//React
import React from 'react'

//Gatsby
import { graphql } from 'gatsby'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Retail employee safety',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      "Employees are beginning to interact again with customers in your retail environment. How can you ensure they're well and your retail environment is safe?",
    descriptionColor: 'white-1',
    image: 'usecaseRetail',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'sky-1',
    bgcolor: 'seafoam-1',
    bggradient: 'deepsea',
  },
  {
    title: 'Automated mobile health reporting for retail',
    titleWeight: 'medium',
    description:
      "Gain current, timely information about employees' health status quickly and easily before their shift via SMS on their mobile device, and log that information for audit",
    image: 'usecaseRetailSolution',
    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'washhands',
  },
]
const featureData = {
  title: 'Reduce risk for your store as you reopen',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Reduce risk',
          colbgcolor: 'dkblue-1',
          iconname: 'shield',
          description:
            'Maintain an auditable log of self-declarations to reduce risk in case of an adverse event',
        },
        {
          title: 'Inspire retail confidence',
          colbgcolor: 'blue-1',
          iconname: 'storefront',
          description:
            "Bring customers back by letting them know you're taking precautions - including health checks",
        },
        {
          title: 'Automated data collection',
          colbgcolor: 'sky-1',
          iconname: 'database',
          description:
            'Modernize away from paper forms and in-person interviews with secure, contactless health assessments on mobile',
        },
      ],
    },
  ],
}
const About = props => (
  <Layout>
    <Helmet
      title="Picohealth - Retail - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your retail establishment',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium dkblue-1">
            Create a safe retail environment
          </h1>
          <p class="display-4">
            Inspire confidence in your customers that you're taking care of your
            employees, and maintaining a safe shopping environment with health
            self-assessments
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="turquoise-1"
      bottomclass="white-4"
      flip={false}
      lineclass="orange-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="pink-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    usecaseConference: file(
      relativePath: { eq: "feature-email-notification-iphone-light-2.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseReviews: file(
      relativePath: { eq: "usecase/protennisoutlet-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: NORTHWEST, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseReviewsServices: file(
      relativePath: { eq: "usecase/jennysdoggroomers-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: NORTHWEST, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseExperience: file(
      relativePath: { eq: "usecase/adventure-rally-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseFestivalEvents: file(
      relativePath: { eq: "usecase/summerfestival-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseSeasonal: file(relativePath: { eq: "usecase/july4-landing.png" }) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseEmployerBrand: file(
      relativePath: { eq: "usecase/holiday-party-title.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
